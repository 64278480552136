body {
  color: #333;
  font-weight: $medium;
  margin: 0;
  background: #fff;
  font-size: $font-size;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row > * {
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 425px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.route-container {
  padding-top: 20px;
  margin-top: 120px;
  max-width: 1320px !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $bold !important;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 23px;
}

.btn {
  color: #fff !important;
  background: $primary-theme !important;
  border: none;
  border-radius: 40px !important;
  padding: 11px 34px 9px !important;
  font-size: 15px;
  margin: 0;
  min-width: 140px;
  font-weight: $bold;
  &:hover {
    color: #fff !important;
    background: $primary-theme-darker !important;
  }
  &.btn-danger{
    color: $red-theme !important;
    background: $red-theme2 !important;
    &:hover{
      color: #fff !important;
      background: $red-theme !important;
    }
  }
  &.btn-secondary{
    color: #999 !important;
    background: #eee !important;
    &:hover{
      color: #fff !important;
      background: #999 !important;
    }
  }
  + .btn{
    margin-left: 10px;
  }
}
