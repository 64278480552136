.route-container-request-password-reset,
.route-container-reset-password {
  height: calc(90vh - 90px);
  align-items: center;
  justify-content: center;
  display: flex;

  .row {
    max-width: 480px;
    margin: 0 auto;
    padding: 60px 50px;
    border-radius: 6px;
    border: 1px solid $border;
    background: $lightgray;

    h1 {
      padding: 0;
      font-size: 35px;
      margin-bottom: 25px;

      strong {
        color: $primary-theme;
      }
    }

    h2 {
      padding: 0;
      font-size: 35px;
      margin-bottom: 25px;
    }

    h1, h3, p {
      text-align: left;
    }

    > div {
      padding: 0;
      margin-left: 0;
      width: 100%;
      text-align: center;
      small{
        display: block;
        font-size: 15px;
        text-align: left;
        margin-bottom: 20px;
      }
      a{
        color: #2eb1c4;
        display: inline-block;
        margin-top: 20px;
        text-decoration: none;
        font-size: 14px;
        font-weight: $bold;
      }
    }

    form {
      margin: 12px 0 0 !important;
      padding: 0;

      .row {
        padding: 0;
        border: none;

        div {
          padding: 0;
        }

        button.btn {
          margin-top: 20px;
        }
      }
    }
  }
}

// password reset
.route-container-request-password-reset{
  .row{
    h2{
      margin-bottom: 5px;
    }
  }
}
