.route-container-dashboard,
.route-container-filtering-records,
.route-container-free-scan {
  margin-bottom: 40px;

  .sub-heading {
    color: #bdbdbd;
  }
  .dashboard-link {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }

  .start-form {
    background-color: #5d5d5d;
    border-radius: 30px;
    padding: 20px;
    opacity: 90%;
  }

  .status-badge {
    color: #565656;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    &.status-in-progress,
    &.status-new {
      background: #ededed;
    }
    &.status-some-progress,
    &.status-some-removed {
      background: #ddfbff;
      color: #2bb3c4;
    }
    &.status-total-progress,
    &.status-removed,
    &.status-total-removed {
      background: #e2ffdd;
      color: #4e9442;
    }
  }

  .stats-row {
    margin: 80px 0 40px;
    padding: 20px;
    border-radius: 20px;
    background: $lightgray;
    border: 1px solid $border;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.17);

    .col {
      padding: 0;
      transition: all 250ms ease-in-out;

      .stats-box {
        height: 120px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        transition: all 200ms ease-in-out;

        &:hover {
          background: #eaeaea;
          border-radius: 10px;
        }
        &.new {
          opacity: 1;
        }
        .stat-value {
          color: $primary-theme;
          font-size: 40px;
          line-height: 1;
          margin-bottom: 2px;

          @media screen and (max-width: 1024px) {
            font-size: 32px;
          }
        }

        .stat-name {
          font-size: 16px;
          font-weight: $bold;
          margin-bottom: 0;
          @media screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      + .col {
        border-left: 1px solid $border;
      }
    }
  }

  .latest-scan-row {
    margin-bottom: 30px;
  }

  .scan-summary-row {
    padding-left: 0;
    padding-right: 0;
    max-width: 1000px;

    margin-right: auto;
    margin-left: auto;

    > .col {
      padding-left: 0;
      padding-right: 0;

      > div {
        + div {
          margin-top: 35px;
        }

        &.addresses {
          .scan-summary-list-item-row {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }

    h2 {
      font-size: 22px;
    }

    .scan-summary-list-item-row {
      min-width: 300px;
      padding: 20px;
      background: $lightgray;
      border: 1px solid $border;
      max-width: 1400px;
      width: 100%;
      margin: 10px auto 24px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px 20px;
      border-radius: 4px;

      .scanResultSummaryCard {
        padding: 0;
        font-size: 16px;
        color: #333;
      }
    }
    .card {
      background: $lightgray;
      border: 1px solid $border;
      padding: 20px;
      height: 100%;
      justify-content: center;
      transition: 0.3s all ease-in-out;

      &:hover {
        background: #fff;
        border: 1px solid $primary-theme;
      }
    }
  }

  .dashboard-map-container {
    width: 100%;
    position: absolute;
    height: 700px;
    left: 0px;
    top: 0;
    display: block;
    pointer-events: none;
    z-index: -1;

    @media screen and (max-width: 1024px) {
      height: 600px;
    }

    [class*='control'],
    [class*='gm-style-cc'] {
      display: none;
    }
  }

  .dashboard-intro-container {
    padding-top: 80px;
    padding-bottom: 40px;
    @media screen and (max-width: 1024px) {
      padding-top: 60px;
      padding-bottom: 30px;
    }
    h1 {
      font-size: 40px;
      color: #fff;
      font-weight: normal;
      span,
      strong {
        color: $primary-theme;
      }
    }
    p {
      color: #858585;
    }
    button {
      background-color: $primary-theme !important;
      svg {
        max-height: 18px;
        position: relative;
        top: -2px;
      }
    }
  }

  .cards-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 24px;
    margin-top: 40px;
    padding-bottom: 80px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 786px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .dashboard-card {
      min-height: 338px;
      padding: 20px;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.1);
      //background: #f3f3f3 url('/assets/card-bg.png') bottom right no-repeat;
      background: #202020 url('/assets/card-bg.png') bottom right no-repeat;
      background-size: 150px 194px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      color: #fff;

      transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1)
        var(--animation-delay);
      opacity: 0;
      transform: translateY(300px);

      &.type-location,
      &.type-phone {
        background-color: #3a5559;
        color: #fff;
      }

      &.type-stat {
        background: $primary-theme;
        color: #fff;
        .card-donut {
          box-shadow: inset 0px 0px 0px 13px $primary-theme-darker;
          .donut-content {
            color: #fff;
          }
          .donut {
            stroke: #ffffff;
            &.is-zero {
              stroke: #ffffff !important;
            }
          }
        }
      }

      &.type-relative,
      &.type-email {
        background-color: #fff;
        color: #323232;
        .card-icon {
          color: white;
          background: rgba(0, 0, 0, 0.4);
        }
        h2 span {
          color: $primary-theme;
        }
      }

      &.type-cta {
      }

      &.is-active {
        opacity: 1;
        transform: translateY(0);
      }

      h2 {
        font-weight: 600;
        max-width: 85%;
      }

      span {
        font-size: 26px;
        max-width: 85%;
        margin-bottom: 20px;

        font-weight: 800;
        text-overflow: ellipsis;
        overflow: hidden;
        // white-space: nowrap;
        display: block;
        position: relative;
        padding-bottom: 10px;
        opacity: 0.7;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 40px;
          height: 4px;
          border-radius: 20px;

          background-color: $primary-theme;
        }
      }

      button {
        border-radius: 30px;
        display: block;
        text-align: center;
        width: 100%;
        padding: 10px 20px;
        border: none;
        background-color: $primary-theme !important;
        font-weight: $bold;
      }

      .card-donut {
        position: relative;
        box-shadow: inset 0px 0px 0px 13px #eaeaea;
        border-radius: 100%;

        $donutCircumference: 307;
        @-webkit-keyframes donut-animation {
          to {
            stroke-dashoffset: calc(
              $donutCircumference +
                calc($donutCircumference * var(--percentage))
            );
          }
        }
        @keyframes donut-animation {
          to {
            stroke-dashoffset: calc(
              $donutCircumference +
                calc($donutCircumference * var(--percentage))
            );
          }
        }

        .donut-content {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          font-weight: 600;
        }

        svg {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }

        .donut {
          stroke-dasharray: $donutCircumference; /* this value is the pixel circumference of the circle */
          stroke-dashoffset: $donutCircumference;
          stroke-width: 12px;
          stroke-linecap: round;
          stroke: $primary-theme;
          &.is-zero {
            stroke: #d7d7d7;
          }
          -webkit-animation: donut-animation 3s ease-out forwards;
          animation: donut-animation 3s ease-out forwards;
        }
      }

      .card-icon {
        height: 50px;
        width: 50px;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 20px;
        }
      }

      .card-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .card-bottom {
        margin-top: auto;
      }
    }
  }

  .reporting-container {
    opacity: 0;
    transform: translateY(300px);
    transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
    &.is-active {
      opacity: 1;
      transform: translateY(0px);
    }
    .section-header {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      grid-gap: 20px;
      margin-bottom: 30px;
      justify-content: center;
      &-item {
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        opacity: 0.4;
        transition: all 250ms ease-in-out;
        padding: 5px;
        &.is-active {
          opacity: 1;
        }
        &:focus-visible {
          outline: #0a53be solid 3px;
          border-radius: 5px;
          opacity: 80%;
        }
      }
    }
    .section-types {
      &-category {
        h2 {
          padding: 20px;
          margin-bottom: 0;
        }
        .row {
          border: 1px solid #ddd;
          margin: 0;
          box-shadow: 0 10px 17px rgba(0, 0, 0, 0.09);
          border-radius: 10px;
        }
      }
      &-item {
        padding: 10px 20px;
        border-top: 1px solid #ddd;
        transition: all 250ms ease-in-out;
        &:first-child {
          border: none;
        }
        &-header {
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          &:hover {
            background: #dfdfdf;
            border-radius: 10px;
          }
          &:focus-visible {
            outline: #0a53be solid 3px;
            border-radius: 5px;
            opacity: 80%;
          }
        }
        .name {
          font-weight: 600;
          font-size: 15px;
        }
        table {
          width: 100%;
          margin-top: 10px;
          tr {
            padding: 5px;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            &:first-child {
              padding-bottom: 10px;
            }
          }
        }
      }

      &-records {
        height: 0;
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
        //max-height: 200px;
        position: relative;
        .subscribe-cta {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
          cursor: pointer;
          button {
            background-color: $primary-theme !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
          }
          + .results-table {
            filter: blur(2px);
            height: 300px;
            overflow: hidden;
          }
        }
        &.is-active {
          height: auto;
          overflow: visible;
        }
      }

      &-record {
        color: #545454;
        font-size: 14px;

        button {
          padding: 2px 20px !important;
          display: table;
          margin-left: auto;
          background: none !important;
          border: 1px solid #dedede;
          min-width: auto;
          color: #454545 !important;
          svg {
            height: 16px;
          }
        }

        .icon-types {
          display: flex;
          flex-wrap: wrap;
          grid-gap: 10px;
          flex-direction: row;

          &-item {
            height: 26px;
            width: 26px;
            display: flex;
            align-items: center;
            justify-content: center;

            .count {
              position: absolute;
              right: -6px;
              top: -3px;
              width: 14px;
              height: 14px;
              font-size: 10px;
              text-align: center;
              font-weight: $bold;
              color: #fff;
              background: $primary-theme;
              border-radius: 100%;
            }

            svg {
              height: 18px;
              filter: brightness(0.8);
            }

            p {
              font-weight: bold;
              margin-bottom: 6px;
            }

            ul {
              margin: 0;
              padding: 0;
              list-style-type: none;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
