form{
  .form-floating{
    label{
      color:#444 !important;
      opacity: 1 !important;
    }
  }
  .form-control{
    border: 1px solid $border;
  }
}