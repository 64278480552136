.route-container-account {
  margin-top: 150px;
  max-width: 920px;
  background: $lightgray;
  padding: 40px;
  border-radius: 6px;

  .title {
    font-size: 42px;
    border-bottom: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .row {
    margin-top: 20px;

    &.grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      margin-top: 0;
    }

    .col {
      h3 {
        font-size: 24px;
        margin-bottom: 0;
      }

      p {
        font-size: 17px;
        line-height: 1.2;
        margin-bottom: 0;

        &.mt {
          margin-top: 20px;
        }

        + p {
          margin-top: 8px;
        }

        &.inactive {
          color: $red-theme;
        }
      }

      .btn {
        margin-top: 20px;
      }
    }
  }
}
