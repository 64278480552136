.route-container-start,
.route-container-signup,
.route-container-free-trial-signup,
.route-container-login {
  align-items: center;
  display: flex;

  @media screen and (max-width: 1234px) {
    justify-content: center;
    margin-top: 60px;
  }

  @media screen and (min-width: 1234px) {
    height: calc(90vh - 90px);
  }

  @media screen and (min-width: 1234px) {
    &::before {
      content: ' ';
      display: block;
      background: #000 url('../../assets/form-background.jpg') center center
        no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
    }
  }

  .row {
    max-width: 640px;
    margin: 0;
    padding: 40px;

    img {
      display: inline;
    }

    h1 {
      padding: 0;
      font-size: 35px;
      margin-bottom: 25px;

      strong {
        color: $primary-theme;
      }
    }

    h2 {
      padding: 0;
      font-size: 35px;
      margin-bottom: 25px;
    }

    h1,
    h3,
    p {
      text-align: left;
    }

    > div {
      padding: 0;
      margin-left: 0;
      width: 100%;
      text-align: center;
      small {
        display: block;
        font-size: 15px;
        text-align: left;
        margin-bottom: 20px;
      }
      a {
        color: #2eb1c4;
        display: inline-block;
        margin-top: 20px;
        text-decoration: none;
        font-size: 14px;
        font-weight: $bold;
      }
    }

    form {
      margin: 12px 0 0 !important;
      padding: 0;

      .row {
        padding: 0;
        border: none;

        div {
          padding: 0;
        }

        button.btn {
          margin-top: 20px;
        }
      }
      .vertical-align-checkbox {
        display: flex;
        align-items: center;
      }
      .terms {
        margin-left: 10px;
        margin-bottom: -2px;
      }
    }
  }
}
