// fonts
$font: 'DM Sans', Arial, sans-serif;
$font-size: 16px;

// media

// window height
$window-height: var(--doc-height);

// font weights (h = Headings, b = Body)
$light: 300;
$regular: 400;
$medium: 500;
$bold: 600;

// media query variables
$large: '64.1em';
$large-awkward: '65em';
$medium: '64em';
$small: '40em';
$small-up: '40.1em';
$xsmall: '36em';

// theme
$primary-theme: #DD7003;
$primary-theme-darker: #b75d09;
$primary-theme2: rgba(233, 214, 2, 0.3);
$red-theme: #f36666;
$red-theme2: rgba(243, 102,102,0.2);

$bg-nav: #000000;

// colors
$primary-color: #ddd;
$primary-color-light: #fff;
$primary-color-dark: #aaa;

// background themes
$gray: #3f3f3f;
$lightgray: #fafafa;
$border: #ddd;