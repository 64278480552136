.footer {
  width: 100%;
  color: #999;
  text-align: center;
  padding: 2px 0;
  display: block;

  p {
    margin: 0;
    font-size: 10pt;
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
  }
}
