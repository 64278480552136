.btn {
  border-radius: 50px;
  padding: 9px 30px;
  font-weight: 900;
}

h1, h2, h3 {
  font-weight: 900;
}

.has-tooltip {
  position: relative;
  cursor: pointer;
  .tooltip {
    position: absolute;
    background: #424242;
    color: #fff;
    padding: 12px;
    bottom: calc(100% + 20px);
    box-shadow: 0 10px 16px -8px rgba(0, 0, 0, 0.17);
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    border-radius: 6px;
    transform: translateY(10px);
    transition: all 200ms ease-in-out;
  }

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.block {
  display: block;
}