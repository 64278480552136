.route-container-filtering-results {
  .first-scan-sites {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    margin: 40px 0;
    p {
      padding: 10px 14px;
      border: 2px solid #000;
      border-radius: 4px;
      color: #000;
      &:last-of-type {
        font-weight: bold;
        background: #000;
        color: #fff;
      }
    }
  }
  h1 {
    font-size: 40px;
    color: #393939;
    font-weight: normal;
    span, strong {
      color: $primary-theme;
    }
  }
  .sub-heading {
    margin: 30px 0;
    color: #2d2d2d;
  }

  .sign-up-cta {
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    h2 {
      font-size: 30px;
    }
  }
}
