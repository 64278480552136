.route-container-profile,
.route-container-complete-profile {
  margin-top: 150px;
  max-width: 640px;
  background: $lightgray;
  padding: 40px;
  border-radius: 6px;
  @media (max-width:425px) {
    padding: 12px;
    .m-p-0{
      padding: 0 0 !important;
    }
  }
  .title {
    font-size: 42px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .sub-header {
    color: #2d2d2d;
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .row {
    margin-top: 20px;
    position: relative;
    padding-left: 25px;
    .col {
      .alert{
        margin-top: 10px;
        margin-bottom: 0;
      }
      h3 {
        font-size: 24px;
        margin-bottom: 0;
        padding-left: 10px;

        &:hover {
          cursor: pointer;
          background-color: darken($color: $lightgray, $amount: 5);
          border-radius: 20px;
        }

      }
      h4 {
        font-size: 14px;
        color: #6a6a6a;
        margin-bottom: 0;
        margin-top: 10px;
      }
      p {
        font-size: 17px;
        line-height: 1.2;
        margin-bottom: 0;
        &.mt {
          margin-top: 20px;
        }
        + p {
          margin-top: 8px;
        }
        &.inactive {
          color: $red-theme;
        }
      }
      .row{
        margin-top: 20px;
        padding: 0 10px;
        .icons{
          top: -6px;
          left: auto;
          right: 25px;
          display: flex;
          align-items: center;
          .icon{
            background: none;

            svg {
              color: #6a6a6a;
            }
          }
        }
        @media (max-width:425px) {
          .icons{
            right: 0px !important;
          }
        }
      }
    }
    .profile-data-item {
      padding-left: 5px;
      &:hover {
        cursor: pointer;
        background-color: darken($color: $lightgray, $amount: 5);
        border-radius: 20px;
      }
    }
    .icons {
      position: absolute;
      top: -2px;
      left: 0;
      width: auto;
      padding: 0;
      margin-right: 5px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-theme;
        color: white;
        border-radius: 100%;
        padding: 0;
        margin: 3px;
        width: 30px;
        height: 30px;

        &:hover {
          cursor: pointer;
          background-color: darken($color: $primary-theme, $amount: 10);
        }

        svg {
          padding: 0;
          margin: 3px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
