.navbar-toggler {
  background-color: #fff;
  padding: 2px 8px;
  .navbar-toggler-icon {
    height: 1.2em;
    width: 1.2em;
  }
  .collapsed.show{
    background-color: transparent;
  }
  + .navbar-collapse {
    .nav-link {
      transition: all 250ms ease-in-out;
    }

  }

  &.collapsed {
    + .navbar-collapse {
      .dropdown-menu {
        left: auto;
        right: 0;
        text-align: right;
        padding: 0;
        overflow: hidden;
        background: #fff;

        a {
          padding: 6px 20px;
          transition: 0.3s all ease-in-out;
          &:hover {
            padding-right: 24px;
            background: $lightgray;
            color: $primary-theme;
          }
        }
      }
    }
  }

  &:not(.collapsed) {
    + .navbar-collapse {
      .nav-link {
        margin: 10px 0px;
      }
    }
  }
}

.navbar {
  background: $bg-nav;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
    .navbar-brand {
      img {
        max-height: 30px !important;
      }
    }
  }

  .container-fluid {
    max-width: 1440px;
    max-width: 100%;
    margin: 0 auto;

    .navbar-collapse {
      flex-direction: row;
      justify-content: flex-end;
      grid-gap: 20px;
    }

    .navbar-brand{
      margin-right: auto;
      cursor: pointer;
    }

    .nav-link {
      font-weight: 900;
      letter-spacing: 0;
      color: #fff;
      font-size: 17px;

      &:after {
        margin-left: 12px;
        position: relative;
        top: 1px;
      }
    }

    .active {
      color: $primary-theme;
    }

    .navbar-brand {
      img {
        max-height: 40px;
      }

      svg {
        filter: invert(1);
      }
    }
  }
}
